@import "../node_modules/roboto-fontface/css/roboto/roboto-fontface.css";
@import "../node_modules/ngx-lightbox/lightbox.css";

$error-color: #ff0000;
$warning-color: #ffee00;

body {
  margin: auto;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.snackbar {
  max-width: 90% !important;
  // margin-left: auto !important; // center align from left
  // margin-right: auto !important; // center align from right
  // margin-bottom: 1rem !important;
  padding: 10px !important;
}

.success {
  background-color: #000000;
}

.warn {
  background-color: #ff2919;
}

.error-text {
  color: $error-color;
}

div {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 200 !important;
  color: #333333;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #333333;
}

h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 300 !important;
}

hr {
  border-width: 0.001rem;
  border-color: #fbfbfb;
}

p {
  font-family: "Roboto", sans-serif;
}
.notification {
  color: white;
}

.center-notification > .mat-simple-snackbar {
  justify-content: center;
}

.dialogWidth {
  width: 30%;
}

.main-div {
  margin: 2rem 3rem 2rem 3rem;
}

.dashboardHeader {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  // display: flex;
  border-bottom: 0.05rem solid #c6cbd4;
}

.dashboardInfo {
  // width: 30%;
  // margin-top: 2%;
}

.dashboardName {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: small;
  color: #7f7f7f;
  text-transform: uppercase;
}

.dashboardOverallStatistics {
  // width: 25%;
  // padding-left: 5rem;
  // margin-top: 2%;
}

.dashboardNoEvaluations {
  /* width: 25%;
  padding-left: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;*/
}

.noEvaluationsLabel {
  font-weight: 500;
  text-align: center;
}

.overallStatistic {
  display: flex;
  margin: 0.5rem;
}

.statisticIcon {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
}

.statisticImage {
  padding: 0.4rem;
}

.statisticLabel {
  font-weight: 300;
  color: #7f7f7f;
}

.statisticData {
  margin-top: 0.7rem;
  margin-left: 0.7rem;
}

.statisticValue {
  font-weight: 500;
}

.dashboardAbsences {
  /*display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  padding-left: 5rem;*/
}

.absencesData {
  margin-left: 0.7rem;
}

.absencesLabel {
  font-weight: 300;
  color: #7f7f7f;
}

.absencesValue {
  font-weight: 500;
}

.dashboardTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.headerLabel {
  font-weight: 300;
  color: #7f7f7f;
}

.headerValue {
  font-weight: 500;
}

.dashboardCharts {
  margin-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-bottom: 0.05rem solid #c6cbd4;
}

.table-link {
  color: #04b5bf !important;
}

.mat-row-link {
  color: #04b5bf !important;
}

.dashboardThird {
  background-color: #1f2532 !important;
  background: #1f2532 !important;
}

.lightText {
  color: #fbfbfb !important;
}

.dashboardSkillCategoryDetails {
  margin-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-bottom: 0.05rem solid #c6cbd4;
}

.dashboardThird.lightText {
  .dark-expansion-header {
    .mat-expansion-panel-header-description,
    .mat-expansion-indicator::after {
      color: #fbfbfb !important;
    }
  }
}
/*
.dashboardThird::selection {
  background-color: #1f2532 !important;
}
*/

.dashboardList {
  margin-top: 1.3rem;
  padding-bottom: 1.3rem;
}

.dashboardDatesDetail {
  margin-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-bottom: 0.05rem solid #c6cbd4;
}

.dashboardPhotoGalleryHeader {
  margin-top: 1.3rem;
  padding-bottom: 1.3rem;
}

.dashboardPhotoGallery {
  display: flex;
  flex-wrap: wrap;
}

.dashboardSection {
  margin-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-bottom: 0.05rem solid #c6cbd4;
}

.categoryDashboardTitle {
  margin-left: 0.7rem;
  margin-top: 0.7rem;
}

.categoryDashboardLabel {
  font-weight: 500;
  color: #7f7f7f;
}

.skillCatDetail {
  border-right: 0.1rem solid #c6cbd4;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*min-width: 80vw;*/
  font-weight: 200;
  /*height: 20rem;*/
  border: 2px dotted #fd2b5c;
  border-radius: 5px;
  background: #d8d8d8;
  margin: 1rem 1rem 3rem 1rem;

  &.hovering {
    border: 2px solid #f16624;
    color: #dadada !important;
  }

  .file-label {
    font-size: 1.2em;
  }
}

.dropzoneSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 300;
  height: 28rem;
  border: 2px dotted #fd2b5c;
  border-radius: 5px;
  background: #d8d8d8;
  width: 100%;

  &.hovering {
    border: 2px solid #f16624;
    color: #dadada !important;
  }

  .file-label {
    font-size: 1.2em;
  }

  p {
    line-height: 1.5;
  }
}

progress::-webkit-progress-value {
  transition: width 0.1s ease;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #cccccc;
}

.physical-bg {
  background-color: $physical-category-color;
}

.tactical-bg {
  background-color: $tactical-category-color;
}

.behavioral-bg {
  background-color: $behavioral-category-color;
}

.technical-bg {
  background-color: $technical-category-color;
}

.sm-icon-button {
  font-size: 1.25rem !important;
}

.loginButtons {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.loginTitle {
  font-weight: bold;
  font-size: 24pt;
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .main-div {
    margin: 0rem !important;
  }

  .dialogWidth {
    width: 80%;
  }

  .dropzoneSmall {
    height: 22rem;

    h2 {
      display: none;
    }

    mat-icon {
      display: none;
    }
  }

  .loginButtons {
    margin-bottom: 1.25rem;
  }
}

.fire-border {
  border-radius: 50%;
}

.backgroundSecondary {
  background-color: $secondary-theme-color;
}

.example-loading-shade {
  position: absolute;
  top: 10rem;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-underline {
  text-decoration: underline;
  text-decoration-color: dimgray;
  text-decoration-style: dotted;
}

.see-image-label {
  background-color: $primary-color;
  color: white;
  font-size: 1rem;
  padding: 1rem 1rem;
}

.small-btn-icon {
  font-size: 0.875rem !important;
}