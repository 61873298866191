/* You can add global styles to this file, and also import other style files */

// cantera
@import "./cantera-clubs-theme.scss";
@import "./common-styles.scss";


body, html {
    height: 100vb;
}

.loader-bg{
    background-image: linear-gradient(180deg, #000000 36%, #333333);
    height: 100vh !important;
    width: 100vw !important;
}

.app-loading {
    width: 100px;
    height: 100px;
    position: static;
    top: 50%;
    left: 50%;
}

.app-loading {
    .logo {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 94px;
        height: 100px;

        // this way asset gets processed by webpack
        background: url(./assets/images/cantera_dark.svg) center center no-repeat;
    }
}

.app-loading .spinner {
    height: 200px;
    width: 200px;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.app-loading .spinner .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #ddd;
}



@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
/*
.bncr-gradient {
  background-image: linear-gradient(180deg, $color-primary 36%, #2c4ba9);
}
*/