@import "~@angular/material/theming";

@include mat-core();

$primary-theme-color: mat-palette($mat-gray, 900);
$accent-theme-color: mat-palette($mat-cyan, 600);

$secondary-theme-color: #fd2b5c;
$primary-color: #520014;

$mat-cantera-clubs-theme: mat-light-theme(
  $primary-theme-color,
  $accent-theme-color
);

$enable-shadows: true;

@include angular-material-theme($mat-cantera-clubs-theme);

$physical-category-color: #00ff87;
$tactical-category-color: #ff015b;
$behavioral-category-color: #e74cf5;
$technical-category-color: #05f1ff;
